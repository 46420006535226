import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { HalfCol, Button } from '../components/common';

import Logo from '../images/Logo-no-font.svg';

const ThankYouPage = ({ data }) => (
  <Layout className="thanks-layout">
    <SEO title="Thank You" keywords={[`hipstu`, `collection`, `shopping`]} />
    <section className="flex flex-col mb-16 items-center py-20 px-5">
      <HalfCol lightText={false} className="text-center items-center">
        <div className="flex flex-col items-center py-4 px-3 mb-6">
          <Logo />
        </div>
        <h1 className="text-5xl mt-3 font-serif text-purple-dark">Thank you!</h1>
        <h1 className="text-4xl mt-1 mb-3 font-serif italic text-purple-dark">We really appreciate it</h1>
        <Button to="/" className="mt-3 max-w-xs">Take me back</Button>
      </HalfCol>
    </section>
  </Layout>
);

export default ThankYouPage;
